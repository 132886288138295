import api from './api';

export const getGallery = async (key) => {
  try {
    const response = await api.get(`/core_images?galeria.llave=${key}&visible=true&order[orden]=asc&groups[]=read`);
    return response.data['hydra:member'];
    } catch (error) {
      console.log(error);
    return[];
  }
}

export const getImage = async (key) => {
  try {
    const response = await api.get(`/core_images?llave=${key}&groups[]=read`);
    return response.data['hydra:member'][0];
    } catch (error) {
      console.log(error);
    return[];
  }
}


export const getImageBase = async (id) => {
  try {
    const response = await api.get(`/core_images?id=${id}&groups[]=base`);
    return response.data['hydra:member'][0]['imageBase64'];
    } catch (error) {
      console.log(error);
    return[];
  }
}

export default { getGallery }
