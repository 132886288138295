"use client";

import "bootstrap/dist/css/bootstrap.min.css";
import styles from '@scss/page/400.module.scss';

import { Fragment } from 'react'
import React from 'react'
import dynamic from 'next/dynamic'
import { HeaderComponent } from '@components/HeaderComponent';
import { FooterComponent } from '@components/FooterComponent';
const ClientWrapper = dynamic(() => import('@/ClienteWrapper'), { ssr: false })

export default function NotFound() {
  return (
    <Fragment>
      <ClientWrapper>
        <HeaderComponent />
      </ClientWrapper>
      <section className='pb-5 pt-4'>
        <div className={`${styles.container} container-fluid px-container`}>
          <h2 className={`${styles.title} color-naranja`}>Error 404</h2>
          <div className={`${styles.separator}`}></div>
          <p className={`${styles.text} color-gris`}>Lo sentimos, no encontramos la página que buscas</p>
        </div>
      </section>
      <ClientWrapper>
        <FooterComponent />
      </ClientWrapper>
    </Fragment>
  )
}
